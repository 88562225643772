import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import '../../styling/customStyle.css';
import Layout from "../Layout/Layout";

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import XLSX from "xlsx";

import { LFC_CARD_STATUS_TYPE, LOG_LEVEL, PAGE_LIMIT, STATUS_CODES } from "../../constants/GlobalConstants";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import logoImage from '../../assets/logo/env-logo.png';
import { CAPTURE_ADD_MINUTE_FOR_END_TIME, CAPTURE_ADJUST_TIME_FOR_DOOR_LOG_IN_SECOND } from "../../constants/LfcContants";
import { UserAuthContext } from "../../contexts/UserAuthContext";
import useCaptureImages from "../../custom-hooks/useCaptureImages";
import useDetectionImages from "../../custom-hooks/useDetectionImages";
import { doorLogGetCountByOrgIdApi, doorLogGetCountByUserIdApi, doorLogGetExportListByOrgId, doorLogGetExportListByUserId, doorLogGetListByOrgIdApi, doorLogGetListByUserIdApi } from "../../services/RouteServices/DoorLogApi";
import { DoorLogData, DoorLogDefault, DoorLogExportListResponseBody, DoorLogListResponseBody } from "../../types/DoorLogType";
import { UserAuthType, UserInfo } from "../../types/UserAuth";
import { DateFilterValue, FilterValue } from "../../types/global/FilterValueType";
import { addMinuteToDate, addSecondToDate, convertDateFormat, convertToLocaleDate, getCurrentLocaleDate } from '../../utils/DateTimeUtil';
import { getDefaultDateFilterValue, getDefaultFilterValue } from "../../utils/DefaultFilterValueUtil";
import { addEventLog } from "../../utils/EventLogUtil";
import { addNormalLog } from "../../utils/LoggerUtil";
import { checkLocation, getAvailableLocations, getCurrentLocalUser, isAdminUser, isSuperSpecialUser, isTenantUser } from "../../utils/UserUtil";
import { Container, InnterTableContainer, PaginationContainer, TableContainer } from "../Shared/Common/Containers";
import { SummaryText, Title } from "../Shared/Common/Titles";
import UnauthorizedPage from "../Shared/ErrorPages/UnauthorizedPage";
import ExportButton from "../Shared/Export/Export";
import DoorLogFilterContainer from "./DoorLogFilterContainer";
import ImageModal from "./ImageModal";

const loadDoorLogExportList = (dataList: DoorLogData[]) => {
    if (!dataList) return []
    return dataList.map(data => {
        return {
            Id: data.id,
            "Digestor Time": convertToLocaleDate(data.lfcTime),
            "Organization": data.organizationName,
            "Location": data.locationName,
            "Machine": data.machineName,
            "Tenant": data.tenantName,
            "Access Card": data.lfcCard,
            "Door Open Duration": data.doorOpenDuration,
            "Status": data.lfcType,
            "Remark": data.remark || "-",
        }
    })
}
 
const DoorLog: React.FC = () => {
    const tableLimit = PAGE_LIMIT;
    const userInfo: UserInfo = getCurrentLocalUser();
    const availableLocations = getAvailableLocations()
    const { hasPermission } = useContext(UserAuthContext) as UserAuthType

    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");

    const [tablePage, setTablePage] = React.useState(1);
    const [tableOffset, setTableOffset] = React.useState(0);
    const [pageCount, setPageCount] = React.useState(0);
    const [doorLogTotalCount, setDoorLogTotalCount] = React.useState(0);
    const [totalDoorOpenCount, setTotalDoorOpenCount] = React.useState(0);
    const [totalDoorScanCount, setTotalDoorScanCount] = React.useState(0);
    
    const [doorLogList, setDoorLogList] = useState<DoorLogData[]>([]); // * Original data object from db
    const [doorLogData, setDoorLogData] = useState<DoorLogData>(DoorLogDefault);

    const [filterValue, setFilterValue] = useState<FilterValue>(getDefaultFilterValue());
    const [dateFilterValue, setDateFilterValue] = useState<DateFilterValue>(getDefaultDateFilterValue())

    const [showCaptureModal, setShowCaptureModal] = useState<boolean>(false)
    const [showOrganicModal, setShowOrganicModal] = useState<boolean>(false)
    const [showNonOrganicModal, setShowNonOrganicModal] = useState<boolean>(false)

    // for fetching capture images
    const startDate = addSecondToDate(convertToLocaleDate(doorLogData?.lfcTime!)!, CAPTURE_ADJUST_TIME_FOR_DOOR_LOG_IN_SECOND)?.toString()!
    const endDate = addMinuteToDate(doorLogData?.lfcTime!, CAPTURE_ADD_MINUTE_FOR_END_TIME)?.toString()!
    const captureDataLimitObj = {
        machineId: doorLogData?.machineId!,
        startDate: startDate,
        endDate: endDate,
    }

    const {images: captureImages, totalCount: captureCount} = useCaptureImages(captureDataLimitObj, [showCaptureModal])
    const {organicImages, nonOrganicImages, error: detectionImageError} = useDetectionImages(captureDataLimitObj, [showOrganicModal, showNonOrganicModal])

    useEffect(() => {
        setTablePage(1)
        setTableOffset(0)
    }, [filterValue, dateFilterValue])

    useEffect(() => {
        setErrorText("")
        setSuccessText("")

        if (hasPermission("viewDoorLog")) {
            if (isTenantUser()) {
                getDoorLogDataByUserId();
                getDoorLogCountByUserId()
            } else {
                getDoorLogDataByOrgId();
                getDoorLogCountDataByOrgId();
            }
        }
    }, [filterValue, dateFilterValue, tableOffset]);

    useEffect(() => {
        if (tablePage > pageCount && pageCount !== 0) {
            setTablePage(pageCount)
            const offsetValue = (tableLimit * (pageCount - 1));
            setTableOffset(offsetValue);
        }
    }, [pageCount])

    useEffect(() => {
        setFilterValue((prev) => ({
            ...prev,
            locationId: 0,
            machineId: 0,
            tenantId: 0
        }))
    }, [filterValue.organizationId])

    useEffect(() => {
        setFilterValue((prev) => ({
            ...prev,
            machineId: 0,
            tenantId: 0
        }))
    }, [filterValue.locationId])

    useEffect(() => {
        setFilterValue((prev) => ({
            ...prev,
            tenantId: 0
        }))
    }, [filterValue.machineId])

    const generatePDF = (dataList: any) => {
        const doc = new jsPDF('landscape', 'mm', 'a4');

        const headers = [
            "Id", "Digestor Time", "Organization", "Machine", "Tenant",
            "Location","Door Open Duration(s)","Access Card", "Status", "Remark",
        ];
        const dataKeys = [
            "id", "lfcTime", "organizationName", "machineName", "tenantName",
            "locationName","doorOpenDuration","lfcCard", "lfcType", "remark"
        ];

        const columnWidths = [15, 30, 30, 30, 30, 30, 25, 25, 20, 50];
        // Generate the columnStyles object dynamically
        const columnStyles: any = {};
        for (let i = 0; i < headers.length; i++) {
          columnStyles[dataKeys[i]] = { cellWidth: columnWidths[i] };
        }

        const getTextColor = (data: any): [number, number, number] => {
            const lfcType = data.row.raw.lfcType;
            if (lfcType === "open" ) {
                return [235, 14, 21];
            } else if (lfcType === "close") {
                return [83, 145, 101]
            }  else {
                return [0, 0, 0]
            }
        };

        autoTable(doc, {
            head: [headers],
            body: dataList,
            columns: dataKeys.map((key) => ({ dataKey: key })),
            columnStyles: columnStyles,
            margin: { top: 20, left: 5},
            didParseCell: function (data) {
                if (data.section === "head") {
                    data.cell.styles.fontSize = 9;
                    data.cell.styles.textColor = [255, 255, 255];
                    data.cell.styles.fillColor = [0, 0, 0];
                } else if (data.section === "body") {
                    if (data.column.dataKey === "lfcType") {
                        const textColor = getTextColor(data)
                        data.cell.styles.textColor = textColor
                    }
                }
            },
            didDrawPage: function (data) {
                doc.setFontSize(18);
                doc.text("Door Log", data.settings.margin.left, 10);
                // Add the logo image
                const logoWidth = 20;
                const logoHeight = 10;
                const logoX = data.settings.margin.left + 250;
                const logoY = 5;
                doc.addImage(logoImage, 'PNG', logoX, logoY, logoWidth, logoHeight);
            }
        })
        doc.save(`doorlogs-${getCurrentLocaleDate()}.pdf`)
    }

    const exportToCSV = async () => { 
        let exportedData = await getExportedData();
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(loadDoorLogExportList(exportedData));
        XLSX.utils.book_append_sheet(workbook, worksheet, "Overall");
        XLSX.writeFile(workbook, `doorlogs-${getCurrentLocaleDate()}.xlsx`);
        const log: string = `${userInfo.userName} has downloaded doorlogs-${getCurrentLocaleDate()}.xlsx`
        addEventLog(
            log,
            "",
            LOG_LEVEL.INFO
        )
        addNormalLog(log, LOG_LEVEL.INFO)
    };

    const exportToPdf = async () => {
        let exportedData = await getExportedData();
        const dataList = exportedData ? exportedData.map((data: DoorLogData) => ({...data, lfcTime: convertToLocaleDate(data.lfcTime)})) : []
        generatePDF(dataList)
        const log: string = `${userInfo.userName} has downloaded doorlogs-${getCurrentLocaleDate()}.pdf`
        addEventLog(
            log,
            "",
            LOG_LEVEL.INFO
        )
        addNormalLog(log, LOG_LEVEL.INFO)
    }

    const openCaptureModal = (doorLog: DoorLogData) => {
        setShowCaptureModal(true)
        setDoorLogData(doorLog)
    }

    const openOrganicModal = (doorLog: DoorLogData) => {
        setShowOrganicModal(true)
        setDoorLogData(doorLog)
    }

    const openNonOrganicModal = (doorLog: DoorLogData) => {
        setShowNonOrganicModal(true)
        setDoorLogData(doorLog)
    }

    const handleChangeFilterValue = (event: ChangeEvent<HTMLSelectElement>) => {
        setFilterValue(
            (prevValue) => {
                return {
                    ...prevValue,
                    [event.target.name]: event.target.value,
                }
            }
        )
    }

    const handleChangeDateFilterValue = (key: "startDate" | "endDate", value: Date) => {
        const day = value.getDate().toString().padStart(2, '0');
        const month = (value.getMonth() + 1).toString().padStart(2, '0');
        const year = value.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;
        setDateFilterValue({...dateFilterValue, [key]: formattedDate})
    }

    const getExportedData = async () => {
        let exportedData = []
        try {
            if (isTenantUser()) {
                exportedData = await getExportDoorLogDataByUserId()
            } else {
                exportedData = await getExportDoorLogDataByOrgId()
            }
        } catch(e) {
            exportedData = []
        }
        return exportedData;
    }

    const getDoorLogCountDataByOrgId = () => {
        const dataLimitsObject = {
            orgId: filterValue.organizationId!,
            locationId: filterValue.locationId,
            machineId: filterValue.machineId!,
            tenantId: filterValue.tenantId!,
            availableLocationIds: availableLocations,
            checkLocation: checkLocation(),
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
        }

        doorLogGetCountByOrgIdApi(dataLimitsObject)
        .then(response => {
            const resData: DoorLogListResponseBody = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const totalCount : number = resData?.data?.totalCount;
                const totalDoorOpenCount : number = resData?.data?.totalDoorOpenCount;
                const totalDoorScanCount : number = resData?.data?.totalDoorScanCount;
                setDoorLogTotalCount(totalCount);
                setTotalDoorScanCount(totalDoorScanCount);
                setTotalDoorOpenCount(totalDoorOpenCount);
                setPageCount((Math.ceil(totalCount/tableLimit)) || 0);;
            }
            else {
                setErrorText(resData?.message)
                console.log ('error');
            }
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    } 

    const getDoorLogDataByOrgId = () => {
        const dataLimitsObject = {
            orgId: filterValue.organizationId!,
            locationId: filterValue.locationId,
            machineId: filterValue.machineId!,
            tenantId: filterValue.tenantId!,
            availableLocationIds: availableLocations,
            checkLocation: checkLocation(),
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            limit: tableLimit,
            offset: tableOffset
        }

        doorLogGetListByOrgIdApi(dataLimitsObject)
        .then(response => {
            const resData: DoorLogListResponseBody = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: DoorLogData[] = resData.data?.doorLogList
                setSuccessText(resData?.message);
                setDoorLogList(dataList);
                setTimeout(() => {
                    setSuccessText("");
                }, 2000);
            }
            else {
                setErrorText(resData?.message)
                console.log ('error');
            }
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    } 

    const getDoorLogCountByUserId = () => {
        const dataLimitsObject = {
            userId: userInfo.id,
            machineId: filterValue.machineId!,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
        }
        
        doorLogGetCountByUserIdApi(dataLimitsObject)
        .then(response => {
            const resData: DoorLogListResponseBody = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const totalCount : number = resData?.data?.totalCount;
                const totalDoorOpenCount : number = resData?.data?.totalDoorOpenCount;
                const totalDoorScanCount : number = resData?.data?.totalDoorScanCount;
                setDoorLogTotalCount(totalCount);
                setTotalDoorScanCount(totalDoorScanCount);
                setTotalDoorOpenCount(totalDoorOpenCount);
                setPageCount((Math.ceil(totalCount/tableLimit)) || 0);
            }
            else {
                setErrorText(resData?.message)
                console.log ('error');
            }
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    } 
    
    const getDoorLogDataByUserId = () => {
        const dataLimitsObject = {
            userId: userInfo.id,
            machineId: filterValue.machineId!,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            limit: tableLimit,
            offset: tableOffset
        }
        
        doorLogGetListByUserIdApi(dataLimitsObject)
        .then(response => {
            const resData: DoorLogListResponseBody = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: DoorLogData[] = resData.data?.doorLogList
                setSuccessText(resData?.message)
                setDoorLogList(dataList);
                setTimeout(() => {
                    setSuccessText("");
                }, 2000);
            }
            else {
                setErrorText(resData?.message)
                console.log ('error');
            }
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    } 
    
    const getExportDoorLogDataByOrgId = async (): Promise<any> => {
        const dataLimitsObject = {
            orgId: isSuperSpecialUser()? filterValue.organizationId! : userInfo.orgId!,
            locationId: filterValue.locationId,
            machineId: filterValue.machineId || 0,
            tenantId: filterValue.tenantId || 0,
            availableLocationIds: availableLocations,
            checkLocation: checkLocation(),
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
        }

        return doorLogGetExportListByOrgId(dataLimitsObject)
        .then(res => {
            const resApiData : DoorLogExportListResponseBody = res?.data;
            return new Promise((resolve, reject) => {
                if (res?.data?.status === STATUS_CODES.SUCCESS_CODE)
                    resolve(resApiData.data)
                else 
                    reject([])
            })   
        }).catch(error => {
            Promise.reject([])
        })
    }
    
    const getExportDoorLogDataByUserId = async (): Promise<any> => {
        const dataLimitsObject = {
            machineId: filterValue.machineId || 0,
            userId: userInfo.id || 0,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
        }

        return doorLogGetExportListByUserId(dataLimitsObject)
        .then(res => {
            const resApiData : DoorLogExportListResponseBody = res?.data;
            return new Promise((resolve, reject) => {
                if (res?.data?.status === STATUS_CODES.SUCCESS_CODE)
                    resolve(resApiData.data)
                else 
                    reject([])
            })   
        }).catch(error => {
            Promise.reject([])
        })
    }

    const handleCloseModal = () => {
        setShowCaptureModal(false)
        setShowOrganicModal(false)
        setShowNonOrganicModal(false)
    }

    const handleChangePage = (event : ChangeEvent<unknown>, pageValue : number) => {
        setTablePage(pageValue);
        if (pageValue === 1) {
            setTableOffset(0);
        }
        else{
            const offsetValue = (tableLimit * (pageValue - 1));
            setTableOffset(offsetValue);
        }
    };
    // * Render Table Data
    const renderTable = () => {
        return (
            doorLogList?.length !== 0 ? doorLogList?.map((doorLog: DoorLogData) =>          
                <tr key={doorLog.id}>
                    <td>{doorLog.id}</td>
                    <td>{convertToLocaleDate(doorLog.lfcTime!) || "-"}</td>
                    { isSuperSpecialUser() &&
                        <>
                        <td>{doorLog.organizationName || "-"}</td>
                        <td>{doorLog.locationName || "-"}</td>
                        </>
                    }
                    <td>{doorLog.machineName || "-"}</td>
                    <td>{doorLog.tenantName || "-"}</td>
                    <td>{doorLog.lfcCard || "-"}</td>
                    {    
                        isSuperSpecialUser() &&                        
                        (doorLog.syncAlready === 1 ?
                            doorLog.lfcType === LFC_CARD_STATUS_TYPE.CLOSE ?
                            <td>
                                <span className="p-2 rounded text-light bg-primary">synced</span>
                            </td>
                            :
                            
                            <td>
                                <span
                                    className={`p-2 rounded text-light ${doorLog.cardId === 1 ? "bg-danger" : doorLog.tenantId ? "bg-success" : "bg-warning"}`}
                                >
                                    synced
                                </span>
                            </td> 
                        :
                        <td></td>)
                    }
                    <td>
                        {
                            doorLog.lfcType !== LFC_CARD_STATUS_TYPE.CLOSE &&
                            <>
                            <button 
                                type="button" 
                                className="btn btn-outline-primary btn-sm"
                                onClick={() => openCaptureModal(doorLog)}
                            >
                                Capture
                            </button>
                            {/* &nbsp;&nbsp;
                            <button 
                                type="button" 
                                className="btn btn-outline-primary btn-sm"
                                onClick={() => openOrganicModal(doorLog)}
                            >
                                Organic
                            </button>
                            &nbsp;&nbsp;
                            <button 
                                type="button" 
                                className="btn btn-outline-primary btn-sm"
                                onClick={() => openNonOrganicModal(doorLog)}
                            >
                                Non-organic
                            </button> */}
                            </>
                        }
                    </td>
                    <td 
                        className={doorLog.lfcType === "open" ? 
                                    "text-danger" : 
                                    doorLog.lfcType === "close" ? "text-success" : ""}>
                            {doorLog.lfcType || "-"}
                    </td>
                    <td>{doorLog.doorOpenDuration || "-"}</td>
                    <td>{doorLog.remark || "-"}</td>
                </tr>
            ): 
            <tr>
                <td>No data found</td>
            </tr>
        )
    }

    if (!hasPermission("viewDoorLog")) {
        return (    
            <Layout>
                <Container>
                    <UnauthorizedPage />
                </Container>
            </Layout>
        )
    }

    return (
        <>
            <Layout>
                <Container>
                    <Box className="d-flex justify-content-between mb-2">
                        <Title>Door Logs</Title>
                        <Box className="titleBox">
                            <ExportButton 
                                exportToCSV={exportToCSV}
                                exportToPdf={exportToPdf}
                            />
                        </Box>
                    </Box>
                    {/* {successText && <Alert severity="success">{successText}<br/></Alert>} */}
                    {errorText && <Alert severity="error" sx={{marginBottom: 2}}>{errorText}</Alert>}

                    <ImageModal 
                        show={showCaptureModal}
                        title={"Capture Images"}
                        handleClose={handleCloseModal}
                        images={captureImages}
                        count={captureCount}
                    />

                    <ImageModal 
                        show={showOrganicModal}
                        title={"Organic Images"}
                        handleClose={handleCloseModal}
                        images={organicImages}
                    />

                    <ImageModal 
                        show={showNonOrganicModal}
                        title={"NonOrganic Images"}
                        handleClose={handleCloseModal}
                        images={nonOrganicImages}
                    />

                    <DoorLogFilterContainer
                        onChangeFilter={handleChangeFilterValue}
                        onChangeDateFilter={handleChangeDateFilterValue}
                        dateFilterValue={dateFilterValue}
                        filterValue={filterValue}
                        userInfo={userInfo}
                    />

                    <div className="d-flex justify-content-between flex-wrap">
                        {
                            (isSuperSpecialUser() || isAdminUser()) &&
                            <SummaryText>Total Door Open - {totalDoorOpenCount || 0} count</SummaryText>
                        }
                        <SummaryText>Total Door Scan - {totalDoorScanCount || 0} count</SummaryText>
                        <SummaryText>{doorLogTotalCount} DoorLog(s) found!</SummaryText>
                    </div>

                    <TableContainer>
                    <InnterTableContainer>
                        <table className="styled-table">
                            <thead className="table-header">
                                <tr>
                                    <th>ID</th>
                                    <th>Digestor Time</th>
                                    { isSuperSpecialUser() &&
                                        <>
                                        <th>Organization</th>
                                        <th>Location</th>
                                        </>
                                    }
                                    <th>Machine</th>
                                    <th>Tenant</th>
                                    <th>Access Card</th>
                                    {
                                        isSuperSpecialUser() &&
                                        <th>Sync</th>
                                    }
                                    <th>Show Images</th>
                                    <th>Status</th>
                                    <th>Door Open Duration(s)</th>
                                    <th>Remark</th>
                                </tr>
                            </thead>
                            <tbody className="table-data">
                                {
                                    renderTable()
                                }
                            </ tbody>
                        </table>
                    </InnterTableContainer>
                    </TableContainer>
                    <PaginationContainer>
                        <Pagination 
                            count={pageCount} 
                            onChange={handleChangePage} 
                            page={tablePage} 
                            variant="outlined" 
                            shape="rounded" size="large" 
                        />
                    </PaginationContainer>
                </Container>
            </Layout>
        </>
    );
};

export default DoorLog;